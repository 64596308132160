<!--
 * @Description: AKJERRT
 * @Date: 2022-07-11 17:35:05
 * @LastEditTime: 2022-07-14 15:48:09
 * @FilePath: \HuiLiang\affair\src\views\account_statement\components\handler\confirm.vue
-->
<template>
  <a-modal
    title="下载选择"
    width="30%"
    :visible="downVisible"
    :footer="null"
    @cancel="downVisible = false"
    :maskClosable="false"
  >
    <a-form-model :label-col="{ span: 4 }" :wrapper-col="{ span: 24 }">
      <a-form-model-item label="">
        <a-radio-group v-model="downDataType" @change="onCheckType">
          <a-radio :value="1" default-checked>仅打印对账单主页</a-radio>
          <a-radio :value="2">打印对账单主页及附件明细</a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="" v-if="downDataType == 2">
        <a-checkbox-group v-model="downData.type" @change="onCheckDetailType">
          <a-checkbox style="margin-left: 10px" v-for="(item, index) in plainOptions" :key="index" :value="item.id">
            <span>{{item.id}}:{{item.name}}</span>
          </a-checkbox>
        </a-checkbox-group><br/>
      </a-form-model-item>
    </a-form-model>
    <div class="footer-bts">
      <!--      <downLoad-->
      <!--        method="post"-->
      <!--        api="/api/order/statements/dealerStatements/exportStatementsPDF"-->
      <!--        :params="{statementId : downData.statementId,type : downData.type }"-->
      <!--        @downLoadDone="downLoadDone"-->
      <!--        @startDownLoad="startDownLoad"-->
      <!--        :isConfirmLoading="loading"-->
      <!--        name="对账单.pdf">下载</downLoad>-->
      <a-button
        @click="downStatements"
        icon="download"
      >下载</a-button>
      <a-button type="default" :loading="loading" @click="downVisible = false">取消</a-button>
    </div>
  </a-modal>
</template>

<script>
import {selectById} from '@/views/account_statement/api/DealerStatementApi';
export default {
  name: 'downStatement',
  data() {
    return {
      handle: '',
      visible: false,
      loading: false,
      downData: {
        statementId:'',
        type: [],
      },
      downDataType:1,
      // plainOptions:['资金账户明细', '额度明细', '欠款明细', '折扣明细'],
      plainOptions:[{id:1,name:'资金账户明细'}, {id:2,name:'额度明细'}, {id:3,name:'欠款明细'}, {id:4,name:'折扣明细'}],
      downVisible: false,
      detail:{},
    }
  },
  methods: {
    onCheckType(val) {
      const value = val.target.value
      if (value == 1) {
        this.downData.type = []
      }else {
        this.downData.type = [1,2,3,4]
      }
      this.downDataType = value
    },
    onCheckDetailType(val) {
      const target = [];
      if (val && val.length > 0) {
        val.forEach(row => {
          target.push(row)
        })
        this.downData.type = target.filter(current => {
          return current !== null && current !== undefined && current !== '';
        })
      }
      console.log('1',this.downData.type)
    },
    downLoadDone() {
      this.loading = false
      this.downVisible = false
    },
    startDownLoad() {
      this.loading = true
    },

    downStatements() {
      // const routeData = this.$router.resolve({
      //   path: '/api/order/statements/dealerStatements/exportStatementsPDF',
      //   query: {
      //     statementId : this.downData.statementId
      //   },
      // })
      // window.open(routeData.href, '_blank')
      window.open(this.$router.resolve({
        path: '/api/order/statements/dealerStatements/exportStatementsPDF',
        query: {
          statementId : this.downData.statementId
        },
      }).href, '_blank')

      setTimeout(() => {
        if (this.downData.type && this.downData.type.length > 0) {
          this.downData.type.forEach(row => {
            if ((row == 1 && this.detail.dealerPayRecordList && this.detail.dealerPayRecordList.length > 0) || (row == 2 && this.detail.limitPayRecordList && this.detail.limitPayRecordList.length > 0) || (row == 3 && this.detail.limitRepaymentList && this.detail.limitRepaymentList.length > 0) || (row == 4 && this.detail.rebatePayRecordList && this.detail.rebatePayRecordList.length > 0)) {
              setTimeout(() => {
                window.open(this.$router.resolve({
                  path: '/api/order/statements/dealerStatements/exportStatementsPDF',
                  query: {
                    statementId : this.downData.statementId,
                    type : [row]
                  },
                }).href, '_blank')
              }, 2000)
            }
          })
        }
      }, 2000)
    },

    init(row) {
      this.$nextTick(() => {
        this.downVisible = true
        this.downData.statementId = row
        this.downData.type = []
        this.downDataType = 1
      })
      selectById(row).then(res => {
        this.detail = res.body
        this.detail.dealerPayRecordList = res.body.dealerPayRecordList
        this.detail.limitPayRecordList = res.body.limitPayRecordList
        this.detail.limitRepaymentList = res.body.limitRepaymentList
        this.detail.rebatePayRecordList = res.body.rebatePayRecordList
      })
    },
  },
}
</script>
